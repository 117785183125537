import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

const Login = lazy(() => import("../views/Login"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));

const ManageInvoice = lazy(() => import("../views/project/index.js"));
const CreateInvoice = lazy(() => import("../views/project/create.js"));

/*****Routes******/

const ThemeRoutes = [
	{
		path: "/",
		element: <FullLayout />,
		children: [
			{
				path: "/",
				element: <Navigate to="manage-invoice" />,
			},
			{
				path: "/manage-invoice",
				exact: true,
				element: <ManageInvoice />,
			},
			{
				path: "/create-invoice",
				exact: true,
				element: <CreateInvoice />,
			},
		],
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/change-password",
		element: <ChangePassword />,
	},
];

export default ThemeRoutes;
